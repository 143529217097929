import Header from '../../../Global/Header/Header'
import { useLocation, useNavigate } from 'react-router-dom';
import BasicQuestions from './BasicQuestions';

const ProposalReport = () => {
    const navigate = useNavigate();
    const { state } = useLocation(); // Access the passed data
    const { record } = state; // Destructure the record

    const handleResubmitClick = () =>{
        navigate('/proposals/resubmit', { state: { record } });
    }

    const handleBackClick = () =>{
        navigate(-1);
    }
    return (
        <>
            <div className="flex bg-gray-100">
                <div className="flex-1 px-10 pb-10 pt-2">
                    <Header />
                    <div className="flex justify-between items-center">
                      {/* Breadcrumb on the left */}
                        <div className="wrapper pt-5">
                            <div className="flex justify-between items-center">
                              <div>
                                <h1 className="text-2xl font-bold text-gray-400 text-left">
                                  <span className="text-gray-800">Proposal Review Report</span>
                                </h1>
                              </div>
                            </div>
                        </div>
                      {/* Table Below */}
                    </div>
                    <div className="wrapper mt-5">
                        <table className="border-2 border-black/30 w-[65%] leading-normal">
                            <thead>
                              <tr className="border-2 border-black/30 py-4">
                                <th className="px-5 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                                  Project Title
                                </th>
                                <th className="px-5 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                                  Program
                                </th>
                                <th className="px-5 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                                  Area
                                </th>
                                <th className="px-5 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                                  Category
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="px-5 py-2">{record.project.projectName}</td>
                                    <td className="px-5 py-2">{record.program}</td>
                                    <td className="px-5 py-2">{record.project.area}</td>
                                    <td className="px-5 py-2">{record.project.category}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* Basic Question and Additional Comments */}
                    <BasicQuestions proposal={record.proposal}/>
                        {/* Submit button */}
                        <div className="text-right">
                          <button 
                            onClick={
                                record.proposal.proposalStatus === 'Accepted' 
                                  ? handleBackClick 
                                  : handleResubmitClick
                              }
                          
                            className={`py-4 px-2 rounded bg-red-500 text-white`}
                            // disabled={!conditionOneChecked || !conditionTwoChecked}
                          >
                            {
                                (record.proposal.proposalStatus === 'Accepted') &&  "Okay" ||
                                (record.proposal.proposalStatus === 'Minor Revision' || 'Major Revision') &&  "Resubmit Proposal"
                            } 
                          </button>
                        </div>
                    </div>
            </div>
        </>
    )
};

export default  ProposalReport;