import React, { useState, useEffect } from "react";
import useGemini from "../../../hooks/Gemini/useGemini";
import Loader from "../../Global/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";

const GroupForm = ({ onSubmit, initialData, developmentArea }) => {
  const { getTagsSuggestions, loading } = useGemini();

  // const [relatedTags, setRelatedTags] = useState([]);
  const [relatedTags, setRelatedTags] = useState(
    initialData.length > 0 ? [...initialData] : []
  );
  const [suggestedTags, setSuggestedTags] = useState([]);

  const getGeminiResponse = async () => {
    try {
      const tags = await getTagsSuggestions(developmentArea);
      const response = tags.split(",").map((tag) => tag.trim()); // Split and clean the response
      setSuggestedTags(response);
    } catch (error) {
      console.error("Error fetching Gemini response:", error);
    }
  };

  // Fetch suggestions when component mounts
  useEffect(() => {
    getGeminiResponse();
  }, [developmentArea]);

  // Fetch more refined suggestions based on developmentArea and selected related tags
  useEffect(() => {
    if (relatedTags.length > 0) {
      const newTags = [developmentArea, ...relatedTags].join(", ");
      getGeminiResponseForRelatedTags(newTags);
    }
  }, [relatedTags, developmentArea]);

  const getGeminiResponseForRelatedTags = async (tags) => {
    try {
      const tagsResponse = await getTagsSuggestions(tags);
      const response = tagsResponse.split(",").map((tag) => tag.trim()); // Split and clean the response
      setSuggestedTags(response);
    } catch (error) {
      console.error("Error fetching more refined Gemini response:", error);
    }
  };

  // Handle adding a tag from suggested to related
  const handleAddTag = (tag) => {
    setRelatedTags([...relatedTags, tag]);
    setSuggestedTags(suggestedTags.filter((item) => item !== tag));
  };

  // Handle removing a tag from related to suggested
  const handleRemoveTag = (tag) => {
    setSuggestedTags([...suggestedTags, tag]);
    setRelatedTags(relatedTags.filter((item) => item !== tag));
  };

  const handleSubmit = () => {
    onSubmit(relatedTags);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="mt-9 mr-32 text-left">
        <h1 className="text-gray-600">
          Project Area:{" "}
          <span className="font-bold text-gray-800">{developmentArea}</span>
        </h1>

        {/* Related Tags Section */}
        <div>
          <h4 className="text-gray-600 mb-3 mt-10">Please add Related Tags:</h4>
          <div className="flex flex-wrap gap-2 mb-4">
            {relatedTags.map((tag) => (
              <div
                key={tag}
                className="flex items-center text-sm px-3 py-1 bg-blue-100 text-blue-800 rounded-full border border-blue-300 cursor-pointer"
              >
                {tag}
                <span
                  className="ml-2 text-red-500 cursor-pointer"
                  onClick={() => handleRemoveTag(tag)}
                >
                  <FontAwesomeIcon
                    icon={faX}
                    className="ml-2 text-red-500 cursor-pointer"
                  />
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Suggested Tags Section */}
        <div>
          <h4 className="text-gray-600 mb-3 mt-10">Suggested:</h4>
          <div className="flex flex-wrap gap-2">
            {suggestedTags.map((tag) => (
              <div
                key={tag}
                className="flex items-center text-sm px-3 py-1 bg-gray-200 text-gray-800 rounded-full border border-gray-300 cursor-pointer hover:bg-gray-300 transition-all duration-200"
                onClick={() => handleAddTag(tag)}
              >
                {tag}
                {/* <span className="ml-2 text-green-500 font-bold">+</span> */}
                <FontAwesomeIcon
                  icon={faPlus}
                  className="ml-2 text-green-500 font-bold"
                />
              </div>
            ))}
          </div>
        </div>
        <button
          className="pt-3 rounded text-gray-400 underline text-sm"
          onClick={getGeminiResponse}
        >
          Suggest more
        </button>

        <div className="mt-8 text-right">
          <button
            className="py-4 px-8 rounded bg-red-500 text-white"
            onClick={handleSubmit}
          >
            Next &gt;&gt;
          </button>
        </div>
      </div>
    </>
  );
};

export default GroupForm;
