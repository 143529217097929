import React, { useState, useEffect } from "react";

const Header = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  // Update the time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  // Determine greeting and color based on the current time
  const getGreetingAndColor = () => {
    const hours = currentTime.getHours();
    if (hours < 12) {
      return { greeting: "Good Morning", color: "bg-[#FED600]" }; // Morning: Yellow
    }
    if (hours < 18) {
      return { greeting: "Good Afternoon", color: "bg-[#00BFFF]" }; // Afternoon: Sky Blue
    }
    return { greeting: "Good Evening", color: "bg-[#FF6347]" }; // Evening: Tomato Red
  };

  const { greeting, color } = getGreetingAndColor();

  // Format date and time
  const formatDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (date) => {
    const time = date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
    return time.replace(" ", " "); // Replaces space with a non-breaking space
  };

  return (
    <div className="text-right">
      <div className="flex items-center justify-end gap-2">
        <p className={`h-4 w-4 rounded-full ${color}`}></p>
        <p>{greeting}</p>
      </div>
      <p className="text-sm flex justify-end">
        <span>{formatDate(currentTime)} · </span>
        <span
          className="inline-block text-mono w-[10ch] text-right whitespace-nowrap"
          style={{ fontVariantNumeric: "tabular-nums" }}
        >
          {formatTime(currentTime)}
        </span>
      </p>
    </div>
  );
};

export default Header;
