import React from "react";

const Breadcrumb = () => {
  return (
    <div className="wrapper pt-5">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-400 text-left">
            <span className="text-gray-800">Your FYP-I Proposals:</span>
          </h1>
          <p className="text-gray-600 text-left">Your students' proposals</p>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
