import React from "react";
import backgroundImage from '../../Assets/titans-bg.png'
import lead from '../../Assets/lead.jpg'
import work from '../../Assets/work.jpg'
import zaeem from '../../Assets/zaeem.jpg'

const TeamTitans = () => {
  return (
    <div
      className="text-white font-sans min-h-screen flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="w-full max-w-screen-lg px-5 relative">
        {/* Top Section */}
        <div className="flex justify-between items-center">
          <div className="text-lg font-semibold">FYP System</div>
          <div>
            <hr className="w-64 ml-20 h-4 border-1" />
          </div>
          <div className="text-lg">
            <a href="https://www.csfyp.live" target="_blank" rel="noopener noreferrer">
              www.csfyp.live
            </a>
          </div>
        </div>

        {/* Title Section */}
        <div className="text-center mt-6">
          <h2 className="text-xl font-bold">OUR TEAM</h2>
          <h3 className="text-4xl font-bold">Meet the Titans team</h3>
          <p className="text-lg">Developed by the Titans</p>
        </div>

        {/* Team Section */}
        <div className="flex flex-col lg:flex-row items-center justify-center mt-8 relative">
          {/* "Leader" Label on the Left */}
          <div className="absolute left-0 top-1/2 transform -translate-y-1/2 -rotate-90 custom-font text-6xl font-semibold">
            Leaders
          </div>
          {/* Team Leads */}
          <div className="grid grid-cols-2 gap-5 lg:gap-10">
            <div className="bg-white p-4 rounded-lg shadow-lg w-52">
              <div className="bg-pink-200 rounded-lg h-32 w-full mx-auto overflow-hidden flex items-center justify-center">
                <img
                  src={lead}
                  alt="Mehwish"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="text-center mt-4">
                <h2 className="text-xl font-semibold text-gray-800">Mehwish</h2>
                <p className="text-gray-600 mt-1">Team Lead</p>
              </div>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-lg w-52">
              <div className="bg-pink-200 rounded-lg h-32 w-full mx-auto overflow-hidden flex items-center justify-center">
                <img
                  src={lead}
                  alt="Humaira"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="text-center mt-4">
                <h2 className="text-xl font-semibold text-gray-800">Humaira</h2>
                <p className="text-gray-600 mt-1">Co-Lead</p>
              </div>
            </div>
          </div>
        </div>

        {/* Horizontal Divider */}
        <div className="inline-flex items-center justify-center w-full my-6">
          <hr className="w-48 mx-auto" />
        </div>

        {/* Force Section */}
        <div
          id="force"
          className="absolute -left-24 top-1/2 transform translate-y-48 -rotate-90 custom-font text-6xl font-semibold"
        >
          Force
        </div>

        {/* Team Members Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-5 ml-20">
          <div className="bg-white p-4 rounded-lg shadow-lg w-56">
            <div className="bg-pink-200 rounded-lg h-32 w-full mx-auto overflow-hidden flex items-center justify-center">
              <img
                src={work}
                alt="Talha Khan"
                className="h-full w-full object-cover"
              />
            </div>
            <div className="text-center mt-4">
              <h2 className="text-xl font-semibold text-gray-800">Talha Khan</h2>
              <p className="text-gray-600 mt-1">Work, Dedication, Faith</p>
            </div>
          </div>

          <div className="bg-white p-4 rounded-lg shadow-lg w-56">
            <div className="bg-pink-200 rounded-lg h-32 w-full mx-auto overflow-hidden flex items-center justify-center">
              <img
                src={zaeem}
                alt="Zaeem Tahir"
                className="h-full w-full object-cover"
              />
            </div>
            <div className="text-center mt-4">
              <h2 className="text-xl font-semibold text-gray-800">Zaeem Tahir</h2>
              <p className="text-gray-600 mt-1">Thought, Temper, Patience</p>
            </div>
          </div>

          <div className="bg-white p-4 rounded-lg shadow-lg w-56">
            <div className="bg-pink-200 rounded-lg h-32 w-full mx-auto overflow-hidden flex items-center justify-center">
              <img
                src={work}
                alt="xD"
                className="h-full w-full object-cover"
              />
            </div>
            <div className="text-center mt-4">
              <h2 className="text-xl font-semibold text-gray-800">xD</h2>
              <p className="text-gray-600 mt-1">Challenge, Resilience, Fight</p>
            </div>
          </div>
        </div>

        {/* Bottom Divider */}
        <div className="inline-flex items-center justify-center w-full mt-6">
          <hr className="w-60 mx-auto" />
        </div>
      </div>
    </div>
  );
};

export default TeamTitans;
