import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import { useGroups } from '../../../hooks/Groups/useGroups';
import Loader from '../../Global/Loader/Loader';

const Table = ({setDisableAdd}) => {
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [records, setRecords] = useState(null);

  const { getFYP1Groups, loading, error } = useGroups();

  useEffect(()=>{
    const fetchRecords = async ()=>{
      const records = await getFYP1Groups()

      if(records.length >= 3)
            setDisableAdd(true);
          
      
      console.log(records)
      setRecords(records)
    }

    fetchRecords();
  }, [])

  const openModal = (record) => {
    setSelectedRecord(record);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRecord(null);
  };

  return (
    <div>
      {loading ? (<div className="flex items-center justify-center">
        <Loader />
      </div>) : 
      (<div className="mt-6 border-2 border-black/30 rounded-lg overflow-hidden text-left">
        <table className="min-w-full leading-normal">
          <thead>
            <tr className="border-b-2 border-black/30 py-4">
              <th className="px-5 py-3 bg-gray-100 text-sm font-semibold text-gray-800 ">
                Group ID
              </th>
              <th className="px-5 py-3 bg-gray-100 text-sm font-semibold text-gray-800 ">
                Name
              </th>
              <th className="px-5 py-3 bg-gray-100 text-sm font-semibold text-gray-800 ">
                Area
              </th>
              <th className="px-5 py-3 bg-gray-100 text-sm font-semibold text-gray-800 ">
                Category
              </th>
            </tr>
          </thead>
          <tbody>
            {((records && records.length > 0)) ? (
              records.map((record) => (
                <tr
                  key={record.group_ID}
                  className="cursor-pointer hover:bg-gray-200"
                  onClick={() => openModal(record)}
                >
                  <td className="px-5 py-5 border-b">{record.group_ID}</td>
                  <td className="px-5 py-5 border-b">{record.project.projectName}</td>
                  <td className="px-5 py-5 border-b">{record.project.area}</td>
                  <td className="px-5 py-5 border-b">{record.project.category}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center py-5">
                  No groups available
                </td>
              </tr>
            )}
          </tbody>

        </table>
      </div>)}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        record={selectedRecord}
        // students={selectedRecord.students}
      />
    </div>
  );
};

export default Table;
