import React, { useEffect, useState } from 'react';
import Table from './Table';
import Header from '../../Global/Header/Header'
import Breadcrumb from './Breadcrumb';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';


const MainContent = () => {
    const navigate = useNavigate();
    const [disableAdd, setDisableAdd] = useState(false);

    const handleClick = () => {
        console.log("navigate button clicked");
        navigate('/fyp-1/add-group');
    }


    return (
      <div className="flex-1 px-10 pb-10 pt-2">
        <Header />
        <div className="flex justify-between items-center">
          {/* Breadcrumb on the left */}
          <Breadcrumb />
          {/* Button on the right */}
          <button
            onClick={handleClick}
            disabled={disableAdd}
            className={`${disableAdd ? 'bg-gray-300 text-gray-600 px-4 py-2 rounded flex items-center cursor-not-allowed' : 'bg-red-500 text-white px-4 py-2 rounded flex items-center'}`}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add New Group
          </button>
        </div>
        <div className="wrapper mt-8">
          <Table setDisableAdd={setDisableAdd}/>
        </div>
      </div>
    );
  };
  
  
  

export default MainContent;
