import React from "react";

const BasicQuestions = ({ proposal }) => {
  const questions = [
    "Project Contains meaningful abstract and achievable Objectives:",
    "The literature review discusses at least 5 relevant studies:",
    "The project justifies the number of members:",
    "Individual Contribution, Gantt Chart and Turnitin Report are provided:"
  ];

  return (
    <div className="pt-5">
      <table className="w-[70%]">
        <thead>
          <tr>
            <th className="text-left font-bold text-lg">Basic Question</th>
            <th className="text-center font-normal">Yes</th>
            <th className="text-center font-normal pl-5">No</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question, index) => (
            <tr key={index} className="">
              <td className="py-2 text-left">{question}</td>
              <td className="text-center">
                <input
                    className="size-5 bg-slate-500 custom-checkbox"
                    type="checkbox"
                    checked={proposal.revision_questions[index] === 1}
                    readOnly
                />
              </td>
              <td className="text-center pl-5">
                <input
                    className="size-5 bg-slate-500 custom-checkbox"
                    type="checkbox"
                    checked={proposal.revision_questions[index] === 0}
                    readOnly
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-left">
        <h1 className="text-left font-bold text-lg py-5">Additional Comments:</h1>
        <textarea className="bg-gray-200 rounded-lg text-black px-2 " name="additional_comments" id="additional_comments" cols={100} rows={5}>
            {proposal.additional_comments}
        </textarea>
        <div className="flex gap-4">
            <h1 className="text-left font-bold text-lg py-5">Final Decision:</h1>
            <h1 className={`${proposal.proposalStatus === "Accepted" ? "bg-green-600" : "bg-[#F0483E]"}text-left font-bold text-lg py-5 text-[#F0483E]`}>{proposal.proposalStatus}</h1>
        </div>
      </div>
    </div>
  );
};

export default BasicQuestions;
