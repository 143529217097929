import React, { useState } from "react";
import Header from "../../../Global/Header/Header";
import Loader from "../../../Global/Loader/Loader";
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useSupabase } from "../../../../hooks/Supabase/useSupabase";
import { useProposals } from "../../../../hooks/Proposals/useProposals";

const UploadFiles = () => {

  const { state } = useLocation();
  const { record } = state;
  const navigate = useNavigate();

  const { uploadFile, loadingSupabase } = useSupabase();
  const { resubmitProposal, loading, error } = useProposals();

  const [wordFile, setWordFile] = useState(null);
  const [wordFileName, setWordFileName] = useState("Browse File");
  const [wordFilePath, setWordFilePath] = useState('');

  const [anonymousFile, setAnonymousFile] = useState(null);
  const [anonymousFileName, setAnonymousFileName] = useState("Browse File");
  const [anonymousFilePath, setAnonymousFilePath] = useState('');

  const [conditionOneChecked, setConditionOneChecked] = useState(false);
  const [conditionTwoChecked, setConditionTwoChecked] = useState(false);
  const [conditionThreeChecked, setConditionThreeChecked] = useState(false);

  const [isModal2Visible, setModal2Visible] = useState(false);


  const handleFileChange = (e, type) => {
    if (type === "word") {
      setWordFile(e.target.files[0]);
      setWordFileName(e.target.files[0].name);

    } else if (type === "anonymous") {
      setAnonymousFile(e.target.files[0]);
      setAnonymousFileName(e.target.files[0].name);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!wordFile || !anonymousFile) {
      alert("Please upload both files.");
      return;
    }

    try {
      const bucketName = process.env.REACT_APP_SUPABASE_BUCKET_NAME;
      const wordPath = await uploadFile(wordFile, bucketName);
      setWordFilePath(wordPath);

      const anonymousPath = await uploadFile(anonymousFile, bucketName);
      setAnonymousFilePath(anonymousPath);

      const submitProposal = await resubmitProposal(record.proposal.proposal_ID, wordPath, anonymousPath)
      setModal2Visible(true);


    } catch (error) {
      console.error(error);
      alert("An error occurred");
    }
  };

  const handleModalClose = () => {
    if(!error)
        navigate('/proposals');
    else
        setModal2Visible(false);
  };

  return (
    <>
    
    {(loadingSupabase || loading) && (
        <Loader />
    )}

<   div className="flex bg-gray-100">
        <div className="flex-1 px-10 pb-10 pt-2">
            <Header />
            <div className="flex justify-between items-center">
              {/* Breadcrumb on the left */}
                <div className="wrapper pt-5">
                    <div className="flex justify-between items-center">
                      <div>
                        <h1 className="text-2xl font-bold text-gray-400 text-left">Proposal Review Report &gt;
                          <span className="text-gray-800"> Resubmit Proposal</span>
                        </h1>
                        <p className="text-gray-600 text-left">Resubmit your project proposal</p>
                      </div>
                    </div>
                </div>
              {/* Files Upload Form */}
            </div>
            <div className="wrapper mt-5">
                <div className="mt-9 mr-32 text-left">
                    <span>Please make sure All required changes are done to the Project Proposal:</span>
                    <form onSubmit={handleSubmit}>
                    {/* Word file upload */}
                        <h1 className="text-lg font-bold mt-7">Word File</h1>
                        <span className="text-red-500 text-sm">
                          Please verify that proposal includes the last page of Turnitin Report + AI Report!
                        </span>
                        <label
                          htmlFor="wordFile"
                          className="flex items-center py-5 px-2 border border-gray-300 rounded-2xl cursor-pointer w-32 mt-4 bg-gray-200 contain-strict"
                        >
                          <FontAwesomeIcon icon={faPaperclip} className="text-gray-600 mr-3 -rotate-45" />
                          <span className="text-gray-700 text-sm">{wordFileName}</span>
                        </label>
                        <input
                          id="wordFile"
                          type="file"
                          accept=".doc,.docx"
                          className="hidden"
                          onChange={(e) => handleFileChange(e, "word")}
                        />

                        {/* Anonymous file upload */}
                        <h1 className="text-lg font-bold mt-7">Anonymous Copy for blind review:</h1>
                        <span className="text-red-500 text-sm">
                          Please verify that this does not include details of the supervisor or the students!
                        </span>
                        <label
                          htmlFor="anonymousFile"
                          className="flex items-center py-5 px-2 border border-gray-300 rounded-2xl cursor-pointer w-32 mt-4 bg-gray-200 contain-strict"
                        >
                          <FontAwesomeIcon icon={faPaperclip} className="text-gray-600 mr-3 -rotate-45" />
                          <span className="text-gray-700 text-sm">{anonymousFileName}</span>
                        </label>
                        <input
                          id="anonymousFile"
                          type="file"
                          accept=".doc,.docx"
                          className="hidden"
                          onChange={(e) => handleFileChange(e, "anonymous")}
                        />

                        {/* Checkboxes for conditions */}
                        <div className="mt-8">
                          <div className="flex items-center mb-4">
                            <input
                              type="checkbox"
                              id="conditionOne"
                              className="mr-2 size-4"
                              checked={conditionOneChecked}
                              onChange={(e) => setConditionOneChecked(e.target.checked)}
                            />
                            <label htmlFor="conditionOne" className="text-sm">
                              Proposal is formatted correctly according to the template.
                            </label>
                          </div>
                          <div className="flex items-center mb-4">
                            <input
                              type="checkbox"
                              id="conditionTwo"
                              className="mr-2 size-4"
                              checked={conditionTwoChecked}
                              onChange={(e) => setConditionTwoChecked(e.target.checked)}
                            />
                            <label htmlFor="conditionTwo" className="text-sm">
                              Proposal includes Abstract, Related Work, Individual Tasks and References.
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              id="conditionThree"
                              className="mr-2 size-4"
                              checked={conditionThreeChecked}
                              onChange={(e) => setConditionThreeChecked(e.target.checked)}
                            />
                            <label htmlFor="conditionThree" className="text-sm">
                                All the necessary changes have been done.
                            </label>
                          </div>
                        </div>

                        {/* Submit button */}
                        <div className="mt-8 text-right">
                          <button
                            type="submit"
                            className={`py-4 px-8 rounded ${
                              conditionOneChecked && conditionTwoChecked && conditionThreeChecked
                                ? "bg-red-500 text-white"
                                : "bg-gray-400 text-gray-200 cursor-not-allowed"
                            }`}
                            disabled={!conditionOneChecked || !conditionTwoChecked || !conditionThreeChecked}
                          >
                            Submit &gt;&gt;
                          </button>
                        </div>
                    </form>

                    {/* Modal 2 */}
                    {isModal2Visible && !loading  && !error && (
                      <div
                        id="modal2"
                        className="absolute top-0 left-0 h-screen w-screen z-100 bg-black/20 flex justify-center items-center"
                      >
                        <div className="p-8 rounded shadow-md bg-gray-100 relative left-[5%] w-[50%]">
                          <div className="flex justify-between mb-6">
                            <h2 className="text-xl font-semibold">FYP Group Added Successfully</h2>
                            <FontAwesomeIcon icon={faTimesCircle} id="close" className="mr-3" onClick={handleModalClose} />
                          </div>
                          <div className="overflow-x-auto mx-auto flex items-center justify-center">
                            <img src="/Assets/Tick.png" alt="Success Tick" />
                          </div>
                          {/* Align button to the right */}
                          <div className="flex justify-end">
                            <button
                              id="confirmBtn"
                              className="bg-red-500 text-white px-8 py-2 rounded mt-4"
                              onClick={handleModalClose}
                            >
                              Confirm &nbsp;&gt;&gt;
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {isModal2Visible && !loading  && error && (
                      <div
                        id="modal2"
                        className="absolute top-0 left-0 h-screen w-screen z-100 bg-black/20 flex justify-center items-center"
                      >
                        <div className="p-8 rounded shadow-md bg-gray-100 relative left-[5%] w-[50%]">
                          <div className="flex justify-between mb-6">
                            <h2 className="text-xl font-semibold">Error occured while adding the groups!</h2>
                            <FontAwesomeIcon icon={faTimesCircle} id="close" className="mr-3" onClick={handleModalClose} />
                          </div>
                          {/* <div className="overflow-x-auto mx-auto flex items-center justify-center">
                            <img src="/Assets/Tick.png" alt="Success Tick" />
                          </div> */}
                          {/* Align button to the right */}
                          <div className="flex justify-end">
                            <button
                              id="confirmBtn"
                              className="bg-red-500 text-white px-8 py-2 rounded mt-4"
                              onClick={handleModalClose}
                            >
                              Confirm &nbsp;&gt;&gt;
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
            </div>
        </div>
     </div>
    </>
  );
};

export default UploadFiles;

