import Table from './Table';
import Header from '../../../Global/Header/Header'
import Breadcrumb from './Breadcrumb';

const FYP1_Proposals = () => (
  <div className="flex bg-gray-100">
    <div className="flex-1 px-10 pt-2">
        <Header />
        <div className="flex justify-between items-center">
          {/* Breadcrumb on the left */}
          <Breadcrumb />
          {/* Button on the right */}
        </div>
        <div className="wrapper mt-8">
          <Table/>
        </div>
      </div>
  </div>
);

export default  FYP1_Proposals;