import { useState } from "react";
import axios from "axios";

axios.defaults.withCredentials = true;

export const useStudents = () => {
  const [loading, setLoading] = useState(false); // Manage loading state
  const [error, setError] = useState(null); // Manage error state
  
  const getNameForRegistrationNumber = async (registration_number) => {
    const token = localStorage.getItem('token');
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}students/available`,
        { studentRegistrationNumber: registration_number },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response || !response.data || !response.data.studentName) {
        throw new Error("Invalid response from server");
      }
      setLoading(false)
      return response.data.studentName; // Return the name if successful
    } catch (error) {
      alert(error.response.data.message);
      return null; // Return null to indicate failure
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return { getNameForRegistrationNumber, loading };
};
