import Header from '../../../Global/Header/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useProposals } from '../../../../hooks/Proposals/useProposals';
import Loader from '../../../Global/Loader/Loader';

const ProposalReport = () => {
    const navigate = useNavigate();
    const { state } = useLocation(); // Access the passed data
    const { record } = state; // Destructure the record
    const { reviewProposal, loading, error } = useProposals();

    const questions = [
        "Project Contains meaningful abstract and achievable Objectives:",
        "The literature review discusses at least 5 relevant studies:",
        "The project justifies the number of members:",
        "Individual Contribution, Gantt Chart and Turnitin Report are provided:"
    ];

    const finalDecisionOptions = [
        "Accept (in current form)",
        "Minor Changes Required",
        "Major Changes Required"
    ];

    const [answers, setAnswers] = useState(new Array(questions.length).fill(null)); // Stores 1 for Yes, 0 for No
    const [comments, setComments] = useState("");
    const [finalDecision, setFinalDecision] = useState("");
    const [isModal2Visible, setModal2Visible] = useState(false);

    const handleCheckboxChange = (index, value) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index] = value;
        setAnswers(updatedAnswers);
    };

    const handleFinalDecisionChange = (decision) => {
        setFinalDecision(decision);
    };

    const handleModalClose = () => {
        setModal2Visible(false);
        if(!error)
            navigate("/proposals");
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const final_decision = 
        finalDecision === 'Accept (in current form)' ? 'Accepted' :
        finalDecision === 'Minor Changes Required' ? 'Minor Revision' :
        finalDecision === 'Major Changes Required' ? 'Major Revision' :
        null;
    
        const formData = {
            proposal_ID: record.proposal.proposal_ID,
            revision_questions: answers,
            additional_comments: comments,
            proposalStatus: final_decision
        };

        console.log("Submitted Data:", formData);
        // Perform your form submission logic here
        const submit = await reviewProposal(formData)
        setModal2Visible(true);
    };

    // Validate form
    const isFormValid = answers.every((answer) => answer !== null) && comments.trim() !== "" && finalDecision !== "";

    return (
        <>
        {loading && <Loader/>}
        <div className="flex">
            <div className="flex-1 px-10 pb-10 pt-2">
                <Header />
                <div className="flex justify-between items-center">
                    <div className="wrapper pt-5">
                        <div className="flex justify-between items-center">
                            <div>
                                <h1 className="text-2xl font-bold text-gray-400 text-left">
                                    <span className="text-gray-800">Review Proposal</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Proposal questionnaire */}
                <form onSubmit={handleSubmit} className="pt-5">
                    <table className="w-[70%]">
                        <thead>
                            <tr>
                                <th className="text-left font-bold text-lg">Basic Questions:</th>
                                <th className="text-right font-normal">Yes</th>
                                <th className="text-right font-normal pl-5">No</th>
                            </tr>
                        </thead>
                        <tbody>
                            {questions.map((question, index) => (
                                <tr key={index}>
                                    <td className="py-2 text-left">{question}</td>
                                    <td className="text-right">
                                        <input
                                            className="size-5 bg-slate-500 custom-checkbox"
                                            type="checkbox"
                                            checked={answers[index] === 1}
                                            onChange={() => handleCheckboxChange(index, 1)}
                                        />
                                    </td>
                                    <td className="text-right pl-5">
                                        <input
                                            className="size-5 bg-slate-500 custom-checkbox"
                                            type="checkbox"
                                            checked={answers[index] === 0}
                                            onChange={() => handleCheckboxChange(index, 0)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="text-left">
                        <h1 className="text-left font-bold text-lg py-5">Additional Comments:</h1>
                        <textarea
                            className="bg-gray-200 rounded-lg text-black px-2"
                            name="additional_comments"
                            id="additional_comments"
                            cols={98}
                            rows={5}
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                        ></textarea>
                    </div>
                    {/* Final Decision Section */}
                    <div className="pt-5 text-left">
                        <h1 className="font-bold text-lg pb-2">Final Decision:</h1>
                        <div className="flex flex-col gap-3 pt-5 w-[25%]">
                            {finalDecisionOptions.map((option, index) => (
                                <label key={index} className="flex items-center justify-between w-full">
                                    <span className="text-lg pr-10">{option}</span>
                                    <input
                                        type="checkbox"
                                        className="size-5 align-middle"
                                        checked={finalDecision === option}
                                        onChange={() => handleFinalDecisionChange(option)}
                                    />
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* Align button to the right */}
                    <div className="flex justify-end">
                        <button
                            id="addBtn"
                            className={`${
                                isFormValid ? "bg-red-500 text-white" : "bg-gray-300 text-gray-600"
                            }  px-8 py-2 rounded mt-4`}
                            type="submit"
                            disabled={!isFormValid}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>

            {/* Modal 2 */}
            {isModal2Visible && !loading  && !error && (
              <div
                id="modal2"
                className="absolute top-0 left-0 h-screen w-screen z-100 bg-black/20 flex justify-center items-center"
              >
                <div className="p-8 rounded shadow-md bg-gray-100 relative left-[5%] w-[50%]">
                  <div className="flex justify-between mb-6">
                    <h2 className="text-xl font-semibold">Review Submitted Successfully!</h2>
                    <FontAwesomeIcon icon={faTimesCircle} id="close" className="mr-3" onClick={handleModalClose} />
                  </div>
                  <div className="overflow-x-auto mx-auto flex items-center justify-center">
                    <img src="/Assets/Tick.png" alt="Success Tick" />
                  </div>
                  {/* Align button to the right */}
                  <div className="flex justify-end">
                    <button
                      id="confirmBtn"
                      className="bg-red-500 text-white px-8 py-2 rounded mt-4"
                      onClick={handleModalClose}
                    >
                      Confirm &nbsp;&gt;&gt;
                    </button>
                  </div>
                </div>
              </div>
            )}
    
            {isModal2Visible && !loading  && error && (
              <div
                id="modal2"
                className="absolute top-0 left-0 h-screen w-screen z-100 bg-black/20 flex justify-center items-center"
              >
                <div className="p-8 rounded shadow-md bg-gray-100 relative left-[5%] w-[50%]">
                  <div className="flex justify-between mb-6">
                    <h2 className="text-xl font-semibold">Error occured submitting response!</h2>
                    <FontAwesomeIcon icon={faTimesCircle} id="close" className="mr-3" onClick={handleModalClose} />
                  </div>
                  {/* Align button to the right */}
                  <div className="flex justify-end">
                    <button
                      id="confirmBtn"
                      className="bg-red-500 text-white px-8 py-2 rounded mt-4"
                      onClick={handleModalClose}
                    >
                      Confirm &nbsp;&gt;&gt;
                    </button>
                  </div>
                </div>
              </div>
            )}
        </div>
        </>
    );
};

export default ProposalReport;

