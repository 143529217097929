import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import {
  faTachometerAlt,
  faUsers,
  faFileAlt,
  faPen,
  faCog,
  faBell,
  faQuestionCircle,
  faChevronDown,
  faEllipsisV,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
  const location = useLocation();
  const [studentsDropdownOpen, setStudentsDropdownOpen] = useState(false);
  const [evalDropdownOpen, setEvalDropdownOpen] = useState(false);
  const [menuDropdownOpen, setMenuDropdownOpen] = useState(false);

  const toggleDropdown = (setter) => () => {
    setter((prev) => !prev);
  };

  const studentRoutes = [
    "/fyp-1/groups",
    "/fyp-1/add-group",
    "/fyp-1/add-group/add-tags",
    "/fyp-1/add-group/add-students",
    "/fyp-1/add-group/check-prerequisites",
    "/fyp-1/add-group/upload-docs",
  ];

  // Automatically manage dropdown state based on the current path
  useEffect(() => {
    if (studentRoutes.includes(location.pathname)) {
      setStudentsDropdownOpen(true); // Keep dropdown open if on a child route
    } else {
      setStudentsDropdownOpen(false); // Close dropdown if no child route is active
    }
  }, [location.pathname]);

  return (
    <>
      {/* // <div className="bg-[#3096FF] text-white max-lg:text-sm max-lg:w-[225px] w-[295px] shrink-0 h-screen flex flex-col"> */}

      {/* Navigation */}
      <nav className="mt-8 text-md flex flex-col gap-2">
        <a
          className={`${
            location.pathname === "/dashboard" && "selected"
          } flex items-center py-2 px-8 hover:bg-[#001F3F]`}
          href="/dashboard"
        >
          <FontAwesomeIcon icon={faTachometerAlt} className="mr-3" />
          <span>Dashboard</span>
        </a>
        <div className="relative">
          <button
            className={`flex items-center py-2 px-8 w-full text-left hover:bg-[#001F3F] focus:outline-none`}
            onClick={toggleDropdown(setStudentsDropdownOpen)}
          >
            <FontAwesomeIcon icon={faUsers} className="mr-3" />
            <span>Students Registered</span>
            <FontAwesomeIcon icon={faChevronDown} className="ml-auto" />
          </button>
          {studentsDropdownOpen && (
            <div>
              <a
                className={`${
                  (location.pathname === "/fyp-1/groups" ||
                    location.pathname === "/fyp-1/add-group" ||
                    location.pathname === "/fyp-1/add-group/add-tags" ||
                    location.pathname === "/fyp-1/add-group/add-students" ||
                    location.pathname ===
                      "/fyp-1/add-group/check-prerequisites" ||
                    location.pathname === "/fyp-1/add-group/upload-docs") &&
                  "selected"
                } text-left pl-20 block py-2 pr-8 hover:bg-[#001F3F]`}
                href="/fyp-1/groups"
              >
                FYP - I
              </a>
              <a
                className="text-left pl-20 block py-2 pr-8 hover:bg-[#001F3F]"
                href="#"
              >
                FYP - II
              </a>
            </div>
          )}
        </div>
        <a
          className={`${
            (location.pathname === "/proposals" ||
              location.pathname === "/proposals/view-report" ||
              location.pathname === "/proposals/resubmit" ||
              location.pathname === "/proposals/download-proposal" ||
              location.pathname === "/proposals/review-proposal" ||
              location.pathname === "/proposals/view-review") &&
            "selected"
          } flex items-center py-2 px-8 hover:bg-[#001F3F]`}
          href="/proposals"
        >
          <FontAwesomeIcon icon={faFileAlt} className="mr-3" />
          <span>Proposals</span>
        </a>
        <div className="relative">
          <button
            className="flex items-center py-2 px-8 w-full text-left hover:bg-[#001F3F] focus:outline-none"
            onClick={toggleDropdown(setEvalDropdownOpen)}
          >
            <FontAwesomeIcon icon={faPen} className="mr-3" />
            <span>Evaluations</span>
            <FontAwesomeIcon icon={faChevronDown} className="ml-auto" />
          </button>
          {evalDropdownOpen && (
            <div>
              <a
                className="text-left pl-20 block py-2 pr-8 hover:bg-[#001F3F]"
                href="#l"
              >
                FYP - I
              </a>
              <a
                className="text-left pl-20 block py-2 pr-8 hover:bg-[#001F3F]"
                href="#"
              >
                FYP - II
              </a>
            </div>
          )}
        </div>
        <a className="flex items-center py-2 px-8 hover:bg-[#001F3F]" href="#">
          <FontAwesomeIcon icon={faCog} className="mr-3" />
          <span>Settings</span>
        </a>
        <a className="flex items-center py-2 px-8 hover:bg-[#001F3F]" href="#">
          <FontAwesomeIcon icon={faBell} className="mr-3" />
          <span>Alerts</span>
          <span className="ml-auto bg-red-500 text-white text-xs rounded-full px-2 py-1">
            01
          </span>
        </a>
        <a className="flex items-center py-2 px-8 hover:bg-[#001F3F]" href="#">
          <FontAwesomeIcon icon={faQuestionCircle} className="mr-3" />
          <span>Help</span>
        </a>
      </nav>
      {/* </div> */}
    </>
  );
};

export default Sidebar;
