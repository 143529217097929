import { Route, Routes } from "react-router-dom";
import SideBar from "../components/Global/SideBar/Sidebar"
import Header from "../components/Global/Header/Header";
import Login from '../components/Login & Forgot Password/Login'
import SupervisorDashboard from '../components/Supervisor/Dashboard/Dashboard'
import FYP_1Page from '../components/Supervisor/FYP1-Groups/FYP_1Page'
import AddGroups from "../components/Supervisor/AddGroups/AddGroups";
import Proposals from "../components/Supervisor/Proposals/Proposals";
import ProposalReport from "../components/Supervisor/Proposals/FYP1-Proposals/ProposalReport";
import ResubmitProposal from '../components/Supervisor/Proposals/FYP1-Proposals/ResubmitProposal'
import DownloadProposal from "../components/Supervisor/Proposals/Assigned Proposals/DownloadProposal";
import ReviewProposal from '../components/Supervisor/Proposals/Assigned Proposals/ReviewProposal'
import ViewReview from '../components/Supervisor/Proposals/Assigned Proposals/ViewReview'
import NotFound from '../components/404 Page/NotFound'
import TeamTitans from "../components/Titans/TeamTitans";

const Router = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/titans" element={<TeamTitans />} />
            <Route exact path="*" element={<NotFound />} />

            <Route element={<SideBar/>}> 
                {/* <Route element={<Header />}>  */}
                    <Route path="/dashboard" element={<SupervisorDashboard />} />
                    <Route path='/fyp-1/groups' element={<FYP_1Page />} />

                    {/* supervisor group add page and its child routes for navigation */}
                    <Route path="/fyp-1/add-group" element={<AddGroups />} > 
                        <Route path="add-tags" element={<AddGroups />} />
                        <Route path="add-students" element={<AddGroups />} />
                        <Route path="check-prerequisites" element={<AddGroups />} />
                        <Route path="upload-docs" element={<AddGroups />} />     
                    </Route>

                    <Route path="/proposals" element={<Proposals/>}/>
                    <Route path="/proposals/view-report" element={<ProposalReport />}/>    
                    <Route path="/proposals/resubmit" element={<ResubmitProposal />}/>
                    <Route path="/proposals/download-proposal" element={<DownloadProposal />}/>
                    <Route path="/proposals/review-proposal" element={<ReviewProposal />}/>
                    <Route path="/proposals/view-review" element={<ViewReview />}/>    

                    {/* Add more routes as needed */}
                {/* </Route> */}
            </Route> 
        </Routes>
    )
}

export default Router