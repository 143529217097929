import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from '../../../Global/Loader/Loader';
import { useProposals } from '../../../../hooks/Proposals/useProposals'

const Table = () => {
  const [records, setRecords] = useState(null);
  const { getReviewedProposals, loading, error } = useProposals();
  const navigate = useNavigate();

  useEffect(()=>{
    const fetchRecords = async ()=>{
      const records = await getReviewedProposals()   
      setRecords(records)
    }
    fetchRecords();
  }, [])

  const handleClick = (record)=> {
    console.log('Navigating with record:', record);
    navigate('/proposals/view-report', { state: { record } });
  }

  return (
    <div>
      {loading && <Loader />}
      <div className="mt-6 border-2 border-black/30 rounded-lg overflow-hidden text-left">
        <table className="min-w-full leading-normal">
          <thead>
            <tr className="border-b-2 border-black/30 py-4">
              <th className="px-5 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                Group ID
              </th>
              <th className="px-5 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                Name
              </th>
              <th className="px-5 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                Status
              </th>
              <th className="px-5 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {(records != null && records.length > 0) ? (
              records.map((record) => (
                <tr
                  key={record.group_ID}
                  className=""
                >
                  <td className="px-5 py-2 border-b">{record.group_ID}</td>
                  <td className="px-5 py-2 border-b">{record.project.projectName}</td>
                  <td className="px-5 py-2 border-b">
                    <span className={`${(
                        record.proposal.proposalStatus === 'Submitted' && 'text-[#346272]' ||
                        record.proposal.proposalStatus === 'Accepted' && 'text-green-600'  ||
                        record.proposal.proposalStatus === 'Minor Revision' && 'text-[#F0483E]' ||
                        record.proposal.proposalStatus === 'Major Revision' && 'text-[#F0483E]'

                    )} block text-left font-bold`}>
                    {/* )} block text-center w-[135px] text-white py-2 px-4 rounded-full text-sm`}> */}
                        {record.proposal.proposalStatus}
                    </span>
                </td>
                  <td className="px-5 py-2 border-b">
                    <button 
                        className={`${(record.proposal.proposalStatus === 'Submitted' ? 'bg-gray-300 text-gray-600' : 'text-white')}
                                bg-[#3096FF] block text-center w-[150px] text-nowrap py-2.5 px-1 rounded-lg text-sm`
                            }
                        // onClick={() => navigate('/proposals/view-report', { state: { record }})}
                        onClick={()=>handleClick(record)}
                        disabled={record.proposal.proposalStatus === 'Submitted'}
                        >
                        View Report
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center py-5">
                  No groups available
                </td>
              </tr>
            )}
          </tbody>

        </table>
      </div>
    </div>
  );
};

export default Table;
