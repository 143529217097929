import React from "react";

const SummaryCard = ({ imgSrc, title, count, link }) => {
  return (
    <div className="rounded-lg shadow border-2 border-black w-[30%] h-[180px]">
      <div className="flex flex-col items-center justify-between h-full pt-4">
        <img src={imgSrc} className="h-[50px] w-[50px]" alt="" />
        <div className="flex flex-col">
          <h3 className="text-xl max-lg:text-base font-bold">{title}</h3>
          <p className="text-sm text-center">{count}</p>
        </div>
        <a href={link} className="text-sm w-full bg-[#3096FF]/30 text-black py-1 px-4 rounded text-right">
          View Details »
        </a>
      </div>
    </div>
  );
};

export default SummaryCard;