import React from 'react'
import FYP1_Proposals from './FYP1-Proposals/FYP1-Proposals'
import AssignedProposals from './Assigned Proposals/AssignedProposals'

const Proposals = () => {
  return (
    <div>
        <FYP1_Proposals />
        <AssignedProposals/>
    </div>
  )
}

export default Proposals