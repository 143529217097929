import { useState } from "react"
import axios from "axios"

axios.defaults.withCredentials =true;
export const useLogin = () => {

    const [error, setError] = useState(null); // Manage error state
    const [loading, setLoading] = useState(false); // Manage loading state


    const login = async (email, password) => {
        setLoading(true);
        setError(null); 
        try {
            console.log(email, password);
            
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}auth/login`, {
                email,
                password
            })

            if (!response)
                throw new Error('Something went wrong')

            localStorage.setItem('token', response.data.token);
            localStorage.setItem('faculty_name', response.data.facultyName);

            return response.data

        } catch (error) {
            const errorMessage = error.response?.data?.message || "An unexpected error occurred";
            setError(errorMessage); // Update error state
            // alert(errorMessage); // Log for debugging
            return null; // Return null to indicate failure
      
        } finally {
          setLoading(false); // Reset loading state
        }
    }
    
    return { login, error, loading }
}