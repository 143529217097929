import Header from '../../../Global/Header/Header'
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faDownload, faSolid } from "@fortawesome/free-solid-svg-icons";
import { useSupabase } from '../../../../hooks/Supabase/useSupabase';
import Loader from '../../../Global/Loader/Loader';

const DownloadProposal = () => {
    const navigate = useNavigate();
    const { state } = useLocation(); // Access the passed data
    const { record } = state; // Destructure the record

    const { downloadFile, loadingSupabase, error } = useSupabase();


    const handleNextClick = () =>{
        navigate('/proposals/review-proposal', { state: { record } });
    }

    const handleDownloadClick = async (filePath) => {
        const download = await downloadFile(process.env.REACT_APP_SUPABASE_BUCKET_NAME, filePath)
        if(download)
            console.log('file downloaded successfully')
    }

    return (
        <>
            {loadingSupabase && <Loader/>}
            <div className="flex bg-gray-100">
                <div className="flex-1 px-10 pb-10 pt-2">
                    <Header />
                    <div className="flex justify-between items-center">
                      {/* Breadcrumb on the left */}
                        <div className="wrapper pt-5">
                            <div className="flex justify-between items-center">
                              <div>
                                <h1 className="text-2xl font-bold text-gray-400 text-left">
                                  <span className="text-gray-800">Review Proposal</span>
                                </h1>
                              </div>
                            </div>
                        </div>
                      {/* Table Below */}
                    </div>
                    <div className="wrapper mt-5">
                        <table className="border-2 border-black/30 w-[65%] leading-normal">
                            <thead>
                              <tr className="border-2 border-black/30 py-4">
                                <th className="px-5 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                                  Project Title
                                </th>
                                <th className="px-5 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                                  Program
                                </th>
                                <th className="px-5 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                                  Area
                                </th>
                                <th className="px-5 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                                  Category
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="px-5 py-2">{record.project.projectName}</td>
                                    <td className="px-5 py-2">{record.program}</td>
                                    <td className="px-5 py-2">{record.project.area}</td>
                                    <td className="px-5 py-2">{record.project.category}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-16 text-left'>
                        <h1 className='text-xl font-bold text-gray-800 mb-5'>
                            Blind Review Copy:
                        </h1>
                        <div className='flex items-center justify-start'>
                            <FontAwesomeIcon icon={faPaperclip} className="text-gray-600 mr-3 -rotate-45" />
                            <span>{record.proposal.blindCopy}</span>
                            <button
                            className='ml-5 bg-[#3096FF] block text-center text-white w-[150px] text-nowrap py-2.5 px-1 rounded-lg text-sm'
                            onClick={()=> handleDownloadClick(record.proposal.blindCopy)}
                            >
                                Download <FontAwesomeIcon icon={faDownload} className="pl-2 text-white font-bold size-4" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Align button to the right */}
            <div className="px-10 flex justify-end items-end">
              <button
                  id="addBtn"
                  className="bg-red-500 text-white px-8 py-2 rounded mt-4"
                  onClick={handleNextClick}
                  >
                    Next &nbsp;&gt;&gt;
              </button>
            </div>
            
        </>
    )
};

export default  DownloadProposal;