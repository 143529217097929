import { useState } from 'react';
import { GoogleGenerativeAI } from '@google/generative-ai';
import apiConfig from './api.json';

const useGemini = (modelName = "gemini-1.5-flash-latest") => {
  const genAI = new GoogleGenerativeAI(apiConfig.GEMINI_KEY);

  const [loading, setLoading] = useState(false)

  const getTagsSuggestions = async (keyword) => {
    console.log("recieved input", keyword)
    setLoading(true);
    try {
      const model = genAI.getGenerativeModel({ model: modelName });
      const result = await model.generateContent(`
        "Suggest five to six highly relevant tags based on the following keyword or keywords: ${keyword}.
        The tags should be specific and closely related to the provided keyword.
        And also make sure that you should not suggest the keywords again that I provide you. 
        Like if I provided you "Web Development" you should not suggest the keywords again

            
        Instructions:
            
        Provide only the suggested tags as a single line of text.
        Separate each tag with a comma and a space (e.g., Web Development, React, Node.js).
        Do not include any numbers, special characters, bullet points, or formatting like * or _.
        Keep the tags concise, meaningful, and directly related to the input keyword.
        Example Scenarios:
            
        If the keyword is Development, your response should be something like:
        "Web Development, Mobile Application Development, React Native, PHP, MERN"
            
        If the keyword is AI/DS, your response should be:
        "Machine Learning, Deep Learning, Data Science, Natural Language Processing, Computer Vision"
            
        If the keyword is MERN, your response should be:
        "React, Node.js, Express.js, MongoDB"
            
        Now generate the tags based on these keywords or keyword: ${keyword}."
        `);
      const response = await result.response;
      const text = await response.text();
      setLoading(false);
      return text;
    } catch (error) {
      console.error("Something went wrong", error);
    }finally {
        setLoading(false);
    }
  };

  return { getTagsSuggestions, loading };
};

export default useGemini;
