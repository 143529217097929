import { useState } from "react";
import axios from "axios";

axios.defaults.withCredentials = true;

export const useProposals = () => {
  const [loading, setLoading] = useState(false); // Manage loading state
  const [error, setError] = useState(null); // Manage error state


  const getReviewedProposals = async () => {
    setLoading(true);
    setError(null); 
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}proposals/getReviewedProposals`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (!response || !response.data) {
        throw new Error("Invalid response from server");
      }
      
      return response.data.data; // Return response data if successful

    } catch (error) {
      const errorMessage = error.response?.data?.message || "An unexpected error occurred";
      setError(errorMessage); // Update error state
      console.error("Group creation error:", errorMessage); // Log for debugging
      return null; // Return null to indicate failure

    } finally {
      setLoading(false); // Reset loading state
    }
  }


  const resubmitProposal = async (proposal_id, wordFile, anonymousFile) => {
    setLoading(true);
    setError(null); 
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}proposals/resubmitProposal`, {
        proposal_ID: proposal_id,
        originalCopy: wordFile,
        blindCopy: anonymousFile
      },{ headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });

      if (!response || !response.data) {
        throw new Error("Invalid response from server");
      }
      
      return response.data.message; // Return response data if successful

    } catch (error) {
      const errorMessage = error.response?.data?.message || "An unexpected error occurred";
      setError(errorMessage); // Update error state
      console.error("Group creation error:", errorMessage); // Log for debugging
      return null; // Return null to indicate failure

    } finally {
      setLoading(false); // Reset loading state
    }
  }


  const getAssignedProposals = async () => {
    setLoading(true);
    setError(null); 
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}proposals/getAssignedProposals`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (!response || !response.data) {
        throw new Error("Invalid response from server");
      }
      
      return response.data.data; // Return response data if successful

    } catch (error) {
      const errorMessage = error.response?.data?.message || "An unexpected error occurred";
      setError(errorMessage); // Update error state
      console.error("Group creation error:", errorMessage); // Log for debugging
      return null; // Return null to indicate failure

    } finally {
      setLoading(false); // Reset loading state
    }
  }


  const reviewProposal = async (formData) => {
    setLoading(true);
    setError(null); 
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}proposals/reviewProposal`, formData,
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });

      if (!response || !response.data) {
        throw new Error("Invalid response from server");
      }
      
      return response.data.message; // Return response data if successful

    } catch (error) {
      const errorMessage = error.response?.data?.message || "An unexpected error occurred";
      setError(errorMessage); // Update error state
      console.error("Group creation error:", errorMessage); // Log for debugging
      return null; // Return null to indicate failure

    } finally {
      setLoading(false); // Reset loading state
    }
  }

  return { getReviewedProposals, resubmitProposal, getAssignedProposals, reviewProposal, loading, error }; // Expose loading and error states
};
