import axios from "axios";
import { useState } from "react";

export const useSupervisor = () => {
  const [loading, setLoading] = useState(false);

  const getCoSupervisors = async () => {
    const token = localStorage.getItem("token");

    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}getCoSupervisorNames`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) setLoading(false);

      return response.data.data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getSupervisorDashboard = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}supervisor/dashboardData`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  };
  return { getCoSupervisors, getSupervisorDashboard, loading };
};
