import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from '../../../Global/Loader/Loader';
import { useProposals } from '../../../../hooks/Proposals/useProposals'

const Table = () => {
  const [records, setRecords] = useState(null);
  const { getAssignedProposals, loading, error } = useProposals();
  const navigate = useNavigate();

  useEffect(()=>{
    const fetchRecords = async ()=>{
      const records = await getAssignedProposals()   
      setRecords(records)
    }
    fetchRecords();
  }, [])

  const handleReviewClick = (record)=> {
    navigate('/proposals/download-proposal', { state: { record } });
  }

  const handleViewReview = (record)=> {
    navigate('/proposals/view-review', { state: { record } });
  }

  return (
    <div>
      {loading && <Loader />}
      <div className="mt-6 border-2 border-black/30 rounded-lg overflow-hidden text-left">
        <table className="min-w-full leading-normal">
          <thead>
            <tr className="border-b-2 border-black/30 py-4">
              <th className="pr-28 pl-5 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                Name
              </th>
              <th className="px-28 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                Status
              </th>
              <th className="px-28 py-3 bg-gray-100 text-base font-semibold text-gray-800 ">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {(records != null && records.length > 0) ? (
              records.map((record) => (
                <tr
                  key={record.group_ID}
                  className=""
                >
                  <td className="pr-28 pl-5 py-2 border-b">{record.project.projectName}</td>
                  <td className="px-28 py-2 border-b">
                    <span className={`block text-left`}>
                        {record.proposal.proposalStatus === 'Submitted' ? 'Not Reviewed' : 'Reviewed'}
                    </span>
                </td>
                  <td className="px-28 py-2 border-b">
                    <button 
                        className={`bg-[#3096FF] block text-center text-white w-[150px] text-nowrap py-2.5 px-1 rounded-lg text-sm`}
                        onClick={() => record.proposal.proposalStatus === 'Submitted' 
                          ? handleReviewClick(record) 
                          : handleViewReview(record)
                        }

                        >
                        {record.proposal.proposalStatus === 'Submitted' ? 'Review' : 'View Review'}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center py-5">
                  No groups available
                </td>
              </tr>
            )}
          </tbody>

        </table>
      </div>
    </div>
  );
};

export default Table;
