import React from "react";
import "./Loader.css";
import cui_logo from '../../../Assets/CUI.png';

const Loader = () => {
  return (
    <div className="fixed inset-0 right-0 bottom-0 bg-gray-300 bg-opacity-60 flex items-center justify-center z-50">
      <img
        src={cui_logo}
        alt="Loading"
        className="loader w-1/2 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg"
      />
    </div>
  );
};



export default Loader;
