// components/ImportantDate.js
import React from "react";

const ImportantDate = () => {
  return (
    <div className="border-2 border-black/30 rounded-sm flex flex-col h-[150px]">
      <div className="flex justify-between items-center px-6 py-3 border-b-2 border-black/30">
        <h3 className="text-base max-lg:text-sm font-bold">Next Important Date</h3>
        <a className="text-[#1D242E] max-lg:text-xs text-sm" href="#">
          Go to Alerts »
        </a>
      </div>
      <div className="flex justify-between px-6 py-2 grow">
        <div className="text-left flex flex-col justify-center">
          <p className="text-xl max-lg:text-lg font-bold">24 - December</p>
          <p className="text-sm max-lg:text-xs">FYP - I Evaluation</p>
        </div>
      </div>
    </div>
  );
};

export default ImportantDate;