import React from 'react';
import backgroundImage from '../../Assets/404-background.png'

const NotFoundPage = () => {
  return (
    <div
      className="bg-[#00203e] text-white font-sans text-center bg-cover bg-no-repeat bg-fixed min-h-screen w-full"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="flex flex-1 flex-col items-center pt-[10%]">
        <img
          src="/Assets/404-logo.png"
          alt="404 Astronaut"
          className="w-[400px] h-auto mb-10 mx-auto"
        />
        <h1 className="text-[50px] font-bold mb-5">Oops!</h1>
        <p className="text-[30px] mb-10">Page Not Found</p>
        <div className="flex justify-center gap-5">
          {/* <button className="bg-transparent border-2 border-white text-white py-3 px-8 text-[16px] cursor-pointer hover:bg-white/10">
            Go Home
          </button>
          <button className="bg-transparent border-2 border-white text-white py-3 px-8 text-[16px] cursor-pointer hover:bg-white/10">
            Go Back
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
